import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core'
import { Box, Button, MenuItem, Select, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { NovaColours } from '@novafuturltd/core'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      // justifyContent: 'flex-end',
      position: 'relative',
      bottom: '0',
      padding: theme.spacing(1),
      marginTop: 'auto !important',
    },
    rowsPerPage: {
      fontSize: '11px',
      marginRight: theme.spacing(0.5),
      opacity: '0.7',
    },
    pagingPanel: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(0.5),
    },
    currentPage: {
      fontSize: '11px',
      marginRight: theme.spacing(0.5),
      opacity: '0.7',
    },
    styledButton: {
      borderRadius: theme.spacing(0.5),
      border: `solid 0.5px ${NovaColours.N070[theme.palette.mode]}`,
      backgroundColor: NovaColours.N100[theme.palette.mode],
      opacity: '0.8',
      fontSize: '14px',
      display: 'flex',
      textAlign: 'center',
      color: NovaColours.N040[theme.palette.mode],
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(0, 0.25),
      cursor: 'pointer',
      outline: 'none',
      alignItems: 'center',
      '&:hover': {
        opacity: '1',
        borderOpacity: '0.7',
      },
      '&:active': {
        backgroundColor: NovaColours.N070[theme.palette.mode],
      },
    },
    previousButton: {
      fill: (props: any) =>
        props.disablePreviousButton && NovaColours.N060[theme.palette.mode],
    },
    nextButton: {
      fill: (props: any) =>
        props.disableNextButton && NovaColours.N060[theme.palette.mode],
    },
    panelContainer: {
      display: 'flex',
    },
  }),
)

interface Props {
  currentPage?: string | number
  disablePageSizes?: boolean
  pageSize?: string | number
  pageSizes?: number[]
  handlePageSizeChange?: any
  handleNextPage?: any
  handlePreviousPage?: any
  disablePreviousButton?: boolean
  infinityPanelLeftBox?: ReactNode | undefined
  disableNextButton?: boolean
  lastPage?: boolean
}

export const InfinityPagingPanel: React.FC<Props> = ({
  currentPage,
  disablePageSizes,
  pageSize,
  pageSizes,
  handlePageSizeChange,
  handleNextPage,
  handlePreviousPage,
  disablePreviousButton,
  infinityPanelLeftBox,
  disableNextButton,
  lastPage,
}) => {
  const intl = useIntl()
  const classes = useStyles({ disablePreviousButton, disableNextButton })
  return (
    <Plugin>
      <Template name="root">
        <TemplatePlaceholder />
        <div className={classes.panel}>
          {infinityPanelLeftBox !== undefined && infinityPanelLeftBox}
          <Box display="flex" justifyContent="flex-end" width="100%">
            {disablePageSizes || (
              <Box alignItems="center">
                <span className={classes.rowsPerPage}>
                  {`${intl.formatMessage({
                    id: 'table.infinity.paging.rows',
                  })}: `}
                </span>
                <Select
                  variant="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pageSize}
                  onChange={handlePageSizeChange}>
                  {pageSizes?.map((size: number, index: number) => (
                    <MenuItem key={index} value={size}>
                      {size}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            <div className={classes.pagingPanel}>
              {currentPage && <i className={classes.currentPage}>
                {intl.formatMessage(
                  { id: 'table.infinity.paging.current.page' },
                  {
                    page: currentPage,
                    lastPage: lastPage ? currentPage : 'many',
                  },
                )}
              </i>}
              <Button
                onClick={handlePreviousPage}
                className={`${classes.styledButton} prev_page`}
                disabled={disablePreviousButton}
              >
                <ArrowBackIosIcon className={classes.previousButton} />
                {intl.formatMessage({ id: 'table.infinity.paging.prev.page' })}
              </Button>
              <Button
                onClick={handleNextPage}
                className={`${classes.styledButton} next_page`}
                disabled={disableNextButton}
              >
                {intl.formatMessage({ id: 'table.infinity.paging.next.page' })}
                <ArrowForwardIosIcon className={classes.nextButton} />
              </Button>
            </div>
          </Box>
        </div>
      </Template>
    </Plugin>
  );
}
